import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import UkraineStrap from "../components/UkraineStrap";

import SEO from "../components/seo";
import "../styles/main.scss";

import Navigation from "../components/Navigation";
import WebsiteFooter from "../components/Footer";

import logo from "../images/logo.svg";

const BackgroundSection = ({ className, children, data }) => {
  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={imageData}
      backgroundColor="#c25803"
    >
      {children}
    </BackgroundImage>
  );
};

const HeaderContainer = styled(BackgroundSection)`
  width: 100%;
  height: 90vh;
  @media (max-width: 768px) {
    height: 60vh;
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  z-index: 0;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.2) 100%
  );
  top: 0;
  left: 0;
  z-index: 1;
`;

const HomepageCenterContent = styled.div`
  text-align: center;
  z-index: 2;
  h2 {
    font-weight: 800;
  }
`;

const Button = styled.div`
  pointer-events: auto;
  cursor: pointer;
  background: #001336;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  color: #c25803;
  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:before {
    content: "";
    background: #c25803;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
  }
  a {
    color: #c25803;
    position: relative;
    mix-blend-mode: difference;
    font-weight: 600;
  }
  &:hover:before {
    transform: translate3d(0, -100%, 0);
  }
`;

const IndexIntro = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 1.5em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .first {
    flex-basis: 45%;
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
    img {
      border-radius: 4px;
    }
  }
  .second {
    flex-basis: 45%;
  }
`;

const IndexServices = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 3em;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .first {
    flex-basis: 45%;
    ul {
      margin-left: 1em;
    }
  }
  .second {
    flex-basis: 45%;
    width: 100%;
    img {
      border-radius: 4px;
    }
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
`;

const IndexSlider = styled.section`
  h3 {
    text-align: center;
    font-weight: 800;
  }
`;

const IndexTestimonialSection = styled.section`
  background-color: #e1eff6;
`;

const IndexTestimonialSectionInner = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  justify-content: space-around;
  align-items: center;
  padding: 6em 0;
  color: #707070;
  strong {
    color: #0a0a0a;
  }
  .first {
    flex-basis: 20%;
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
  .second {
    flex-basis: 50%;
  }
`;

const IndexTestimonial = styled.blockquote`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  line-height: 150%;
  &:before {
    content: "“";
    font-family: Georgia;
    line-height: 100%;
    font-size: 120px;
    font-weight: bold;
    color: #e0ddd7;
    position: absolute;
    left: -40px;
    top: -30px;
    z-index: -1;
  }
  &:after {
    content: "";
  }
  span {
    font-family: Georgia;
    font-weight: bold;
    font-style: italic;
    margin-top: 1em;
  }
`;

const IndexFormSection = styled.section`
  background: #f7faf8;
  display: flex;
  padding: 3em 0;
`;

const IndexForm = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  text-align: center;
  padding: 1em;
  z-index: 1;
  h3 {
    font-weight: 800;
  }
  h5 {
    font-weight: 800;
    color: #707070;
  }
  form {
    input,
    textarea {
      width: 60%;
      border-bottom: 1px solid black;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    button {
      background: #c25803;
      border: unset;
      padding: 1em 2.5em;
      color: white;
      transition: 0.3s;
      margin-bottom: 0;
      &:hover {
        background: black;
        color: white;
      }
    }
  }
`;

const settings = {
  infinite: true,
  autoplay: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      manfredShottenShowhouse: file(
        relativePath: { eq: "manfred-shotten-showhouse.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      services: file(relativePath: { eq: "antique-marketing-services.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      williamWalter: file(relativePath: { eq: "williamWalterLogo.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderLogo1: file(relativePath: { eq: "dealer-logos/2covet.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo2: file(relativePath: { eq: "dealer-logos/3details.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo3: file(
        relativePath: { eq: "dealer-logos/ken-bolan-studio.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo4: file(relativePath: { eq: "dealer-logos/loveday.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo5: file(
        relativePath: { eq: "dealer-logos/manfred-schotten.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo6: file(
        relativePath: { eq: "dealer-logos/mayflower-antiques.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo7: file(
        relativePath: { eq: "dealer-logos/penman-antiques-fairs.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo8: file(
        relativePath: { eq: "dealer-logos/peter-bunting.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo9: file(
        relativePath: { eq: "dealer-logos/philip-carrol-antiques.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo10: file(relativePath: { eq: "dealer-logos/steve-sly.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo11: file(relativePath: { eq: "dealer-logos/waxantiques.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo12: file(
        relativePath: { eq: "dealer-logos/wick-antiques.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sliderLogo13: file(
        relativePath: { eq: "dealer-logos/william-walter.png" }
      ) {
        childImageSharp {
          fixed(quality: 80, width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Home" />
      <Navigation showLogo={false} background={false} />
      <HeaderContainer data={data}>
        <Overlay />
        <HomepageCenterContent className="container">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ rotate: 360, scale: 1 }}
            transition={{
              delay: 0.5,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <img
              src={logo}
              alt="The Antique Marketing Company"
              style={{ width: 700 }}
            />
          </motion.div>
          <h2>Helping Antique Dealers in the online world</h2>
          <Button>
            <Link to="/contact">Get in touch</Link>
          </Button>
        </HomepageCenterContent>
      </HeaderContainer>
      <IndexIntro className="container">
        <div className="first">
          <Img
            fluid={data.manfredShottenShowhouse.childImageSharp.fluid}
            alt="Manfred Shotten Show House"
          />
        </div>
        <div className="second">
          <p>
            <strong>
              The Antique Marketing Company is a Digital Marketing and Social
              Media Agency
            </strong>
            , specifically helping Antique and Art Dealers navigate the online
            world. We provide a range of marketing services, ranging from but
            not limited to Social Media Management and Digital Content Creation,
            to SEO and Google Ad Words.
          </p>
          <p>
            Our specialism within the Antique and Art Industry sets us apart
            from other marketing agencies, as our services are directed at
            specific clients within this field, looking to expand their online
            presence and maximise their digital sales.
          </p>
        </div>
      </IndexIntro>
      <IndexServices className="container">
        <div className="first">
          <p>
            <strong>Services</strong>
            <br />
            The Antique Marketing Company's services include:
          </p>
          <ul>
            <li>
              Bespoke website design and development for antique and art dealers
            </li>
            <li>Hosting and maintenance packages</li>
            <li>Social media management and strategy</li>
            <li>Newsletter and digital marketing services</li>
            <li>Photography and videography</li>
            <li>Branding and graphic design</li>
            <li>Stock and marketplace management</li>
            <li>Online PR</li>
            <li>Consultancy</li>
            <li>Event support and management</li>
            <li>SEO</li>
            <li>Google Ad-Words and PPC</li>
          </ul>
        </div>

        <div className="second">
          <Img
            fluid={data.services.childImageSharp.fluid}
            alt="Services provided by The Antiques Marketing Company"
          />
        </div>
      </IndexServices>
      <IndexSlider className="container">
        <h3>Dealers we work with</h3>
        <Slider {...settings} style={{ overflow: "hidden" }}>
          <a href="https://2covet.com" target="_blank" rel="noreferrer">
            <Img fixed={data.sliderLogo1.childImageSharp.fixed} />
          </a>
          <a href="https://3details.com/home" target="_blank" rel="noreferrer">
            <Img fixed={data.sliderLogo2.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.kenbolanstudio.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo3.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.lovedayantiques.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo4.childImageSharp.fixed} />
          </a>
          <a
            href="https://sportantiques.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo5.childImageSharp.fixed} />
          </a>
          <a
            href="http://mayflower-antiques.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo6.childImageSharp.fixed} />
          </a>
          <a href="https://countryoak.co.uk/" target="_blank" rel="noreferrer">
            <Img fixed={data.sliderLogo8.childImageSharp.fixed} />
          </a>
          <a href="https://philipcarrol.com/" target="_blank" rel="noreferrer">
            <Img fixed={data.sliderLogo9.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.steveslyjapaneseart.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo10.childImageSharp.fixed} />
          </a>
          <a href="https://waxantiques.com/" target="_blank" rel="noreferrer">
            <Img fixed={data.sliderLogo11.childImageSharp.fixed} />
          </a>
          <a
            href="https://wickantiques.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo12.childImageSharp.fixed} />
          </a>
          <a
            href="https://williamwalter.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <Img fixed={data.sliderLogo13.childImageSharp.fixed} />
          </a>
        </Slider>
      </IndexSlider>
      <IndexTestimonialSection>
        <IndexTestimonialSectionInner className="container">
          <div className="first">
            <Img fluid={data.williamWalter.childImageSharp.fluid} />
          </div>
          <IndexTestimonial className="second">
            <div>
              William Walter Antiques ltd has been with{" "}
              <strong>The Antique Marketing Company</strong> (formally{" "}
              <strong>SocialMe LTD</strong>) since its creation and always found
              Zara and her team so easy to work with . They understand the
              business and have given excellent advice over years , that has
              been of great benefit to the company and increased our sales.
            </div>
            <span>Elizabeth, William Walter Antiques</span>
          </IndexTestimonial>
        </IndexTestimonialSectionInner>
      </IndexTestimonialSection>
      <IndexFormSection>
        <IndexForm className="container container__extra_small">
          <h3>Get in touch</h3>
          <h5>Fill out the form below and someone will be in touch</h5>
          <form
            action="https://getform.io/f/c3c2ee62-1982-47fe-9909-5a17bbac5fba"
            method="POST"
          >
            <p>
              <input placeholder="Name" required type="text" name="name" />
            </p>
            <p>
              <input placeholder="Phone Number" type="tel" name="phone" />
            </p>
            <p>
              <input placeholder="Email Address" type="email" name="email" />
            </p>
            <p>
              <textarea placeholder="Message" name="message" />
            </p>
            <p>
              <button type="submit">Submit</button>
            </p>
          </form>
        </IndexForm>
      </IndexFormSection>
      <WebsiteFooter />
    </>
  );
};

export default IndexPage;

BackgroundSection.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
};
