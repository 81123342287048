import React from "react";
import styled from "styled-components";

const Strap = styled.div`
  background: #001336;
  color: white;
  text-align: center;
`;

const UkraineStrap = () => (
  <Strap>
    <p>
      The Antique Marketing Company stands with our friends, family &amp; all of
      the people in Ukraine.{" "}
      <a
        href="https://donate.redcrossredcrescent.org/ua/donate/~my-donation"
        target="_blank"
        rel="noreferrer"
      >
        Donate here
      </a>
    </p>
  </Strap>
);

export default UkraineStrap;
